@import '../../../constants/variables.scss';

.house {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  margin-bottom: 15px;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }

  &__img-overlay {
    position: relative;
    display: flex;
    max-width: calc(100vw - 30px);
    max-height: calc(73vw - 30px);;
    margin-bottom: 10px;
  }

  &__img {
    width: calc(100vw - 30px);
    max-width: 600px;
    height: calc(73vw - 30px);
    max-height: 396px;
    object-fit: cover;
  }

  &__main-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p, ul {
      font-size: 13px;
      line-height: 15px;
      margin: 0 0 10px;
    }

    ul {
      margin: 0 0 10px;
      padding: 0;
      list-style: none;
    }
  }

  &__title {
    margin-bottom: 7px;
  }

  &__prices {
    margin: 0 0 10px;
    padding: 0;
    list-style: none;

    li {
      font-size: 17px;
      line-height: 19px;
      font-weight: 600;
    }
  }

  &__button-reserve {
    font-size: 14px;
    line-height: 17px;
    font-family: inherit;
    font-weight: 400;
    padding: 4px 10px;
    border-radius: 4px;
    border: 1px solid $primary-color;
    background-color: $primary-color;
    color: #fff;

    &:hover {
      cursor: pointer;
      color: $primary-color;
      background-color: transparent;
      transition: all 0.3s ease-out;
    }
  }

  @media (min-width: 768px) {
    max-width: 738px;
    flex-direction: row;
    margin-bottom: 30px;

    &__img-overlay {
      margin-bottom: 0;
      margin-right: 20px;
      width: 450px;
      height: 297px;
    }

    &__img {
      width: 450px;
      height: 297px;
    }

    &__main-info {
      width: 100%;
    }
  }

  @media (min-width: 1360px) {
    max-width: 1200px;
    margin-bottom: 50px;

    &__img-overlay {
      margin-right: 40px;
      width: 760px;
      height: 500px;
    }

    &__img {
      width: 760px;
      height: 500px;
      max-width: 760px;
      max-height: 500px;
    }

    &__main-info {
      p, ul {
        font-size: 17px;
        line-height: 19px;
        margin: 0 0 20px;
      }

      ul {
        margin: 0 0 20px;
      }
    }

    &__title {
      margin-bottom: 20px;
      font-size: 30px;
      line-height: 35px;
    }

    &__prices {
      margin: 0 0 20px;

      li {
        font-size: 25px;
        line-height: 30px;
        font-weight: 700;
      }
    }

    &__button-reserve {
      font-weight: 600;
      padding: 10px 25px;
      margin-top: 10px;
    }
  }
}

.swiper-button-next {
  background-image: url(./../../../images/arrow-right.svg);
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.7;

  &:hover {
    cursor: pointer;
    opacity: 0.85;
    transition: opacity 0.3s ease-out;
  }
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  background-image: url(./../../../images/arrow-left.svg);
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.7;

  &:hover {
    cursor: pointer;
    opacity: 0.85;
    transition: opacity 0.3s ease-out;
  }}

.swiper-button-prev::after {
  display: none;
}
