.photo-grid {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 90px 20px 30px !important;
  max-width: 620px;
  margin: 0 auto;

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  h2 {
    margin-bottom: 41px;
  }

  ul {
    list-style-type: circle;
    margin: 0 0 15px;
    padding: 0 0 0 20px;
  }

  li, p {
    line-height: 25px;
  }

  &__pics {
    width: 100%;
    display: grid;
    grid-template-areas:
      'pic-1 pic-1 pic-2'
      'pic-1 pic-1 pic-3'
      'pic-4 pic-4 pic-4';
    gap: 20px;
    margin: 60px 0 0;
  }

  &__pic-1 {
    background: url(../../images/photoGrid/IMG_9783.webp) center center / cover no-repeat;
    grid-area: pic-1;
    aspect-ratio: 1;
  }

  &__pic-2 {
    background: url(../../images/photoGrid/IMG_9781.webp) center center / cover no-repeat;
    grid-area: pic-2;
    aspect-ratio: 1;
  }

  &__pic-3 {
    background: url(../../images/photoGrid/IMG_9780.webp) center center / cover no-repeat;
    grid-area: pic-3;
    aspect-ratio: 1;
  }

  &__pic-4 {
    background: url(../../images/photoGrid/IMG_9664.webp) center center / cover no-repeat;
    grid-area: pic-4;
    aspect-ratio: 2 / 1;
  }

  @media (min-width: 768px) {
    max-width: 738px;
    margin-bottom: 50px;

    ul {
      padding-left: 30px;
    }

    li, p {
      font-size: 16px;
      line-height: 25px;
    }
  }

  @media (min-width: 1360px) {
    max-width: 1200px;
    padding: 120px 20px 90px !important;

    li, p {
      font-size: 17px;
      line-height: 25px;
    }

    &__content {
      width: 760px;
      align-self: flex-start;
      margin-left: 100px;
    }
  }
}
