@import url(./vendor/fonts/fonts.css);

body {
  margin: 0;
  font-family: "Gilroy", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  font-size: 26px;
  line-height: 30px;
  font-family: inherit;
  font-weight: 700;
  color: #333;
  margin: 0;
}

h3 {
  font-size: 19px;
  line-height: 22px;
  font-family: inherit;
  font-weight: 700;
  color: #333;
  margin: 0;
}

p, li, span {
  font-size: 14px;
  line-height: 19px;
  font-family: inherit;
  font-weight: 400;
  color: #333;
  margin: 0;
}

@media (min-width: 768px) {
  h2 {
    font-size: 48px;
    line-height: 56px;
  }
}

@media (min-width: 1360px) {
  h2 {
    font-size: 52px;
    line-height: 56px;
  }
}
