@import '../../constants/variables.scss';

.menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  z-index: 10;
  font-family: 'Century Gothic';

  &_open {
    position: fixed;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    cursor: pointer;
  }

  &__burger-btn-overlay {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    background-color: transparent;
    cursor: default;

    &_open {
      background-color: rgb(30, 30, 30);
    }
  }

  &__overlay {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 30px 0 60px;
    background-color: rgb(30, 30, 30);
    cursor: default;
  }


  &__overlay_open {
    display: flex;
  }

  ul {
    margin: 0 0 50px;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
  }

  li {
    font-size: 16px;
    line-height: 16px;
    color: #fff;
    font-family: inherit;
    font-weight: 400;
    text-align: center;
    margin-bottom: 20px;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
      transition: opacity 0.2s ease-out;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }



  button {
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    font-family: inherit;
    font-weight: 400;
    padding: 7px 20px;
    border-radius: 5px;
    border: none;
    background-color: $primary-color;
    border: 2px solid $primary-color;

    &:hover {
      cursor: pointer;
      background-color: transparent;
      transition: all 0.3s ease-out;
    }
  }

  /* burger icon */

  &__burger-btn {
    width: 28px;
    height: 21px;
    align-self: flex-end;
    position: relative;
    transform: rotate(0deg);
    transition: 0.6s ease-in-out;
    cursor: pointer;
    margin: 20px;

    span {
      display: block;
      height: 3px;
      width: 100%;
      position: absolute;
      left: 0;
      background: #fff;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        top: 0px;
      }

      &:nth-child(2) {
        top: 9px;
      }

      &:nth-child(3) {
        top: 18px;
      }
    }

    &_open span:nth-child(1) {
      transform: rotate(45deg);
      top: 9px;
    }

    &_open span:nth-child(2) {
      width: 0%;
      left: 50%;
    }

    &_open span:nth-child(3) {
      transform: rotate(-45deg);
      top: 9px;
    }
  }

  @media (min-width: 1200px) {
    position: fixed;

    &__burger-btn-overlay {
      display: none;
    }

    &__overlay {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      max-width: 1920px;
      margin: 0 auto;
      padding: 20px;
      background-color: transparent;
      box-sizing: border-box;

      &_bg_dark {
        background: rgba(0, 0, 0, 0.5);
      }
    }

    &__logo {
      position: absolute;
      top: 10px;
      left: calc(50% - 100px);
      margin-bottom: 0;
    }

    ul {
      flex-direction: row;
      margin-bottom: 0;
    }

    li {
      margin-bottom: 0;
      margin-right: 20px;
    }

    &__right-wrap {
      flex-direction: row;
    }
  }
}
