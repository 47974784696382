
.footer {
  width: 100%;
  display: flex;
  align-items: center;
  border-top: 1px solid #292929;
  background-color: #111;
  min-height: 80px;
  padding: 20px;
  box-sizing: border-box;

  &__container {
    display: flex;
    flex: 1 1 auto;
    max-width: 1200px;
    margin: 0 auto;
  }

  &__text {
    color: #fff;
    font-size: 11px;
    line-height: 15px;
    font-family: inherit;
    font-weight: 400;
  }

  @media (min-width: 768px) {
    padding: 20px 40px;
  }

  @media (min-width: 1360px) {
    &__text {
      font-size: 13px;
    }
  }
}
