@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-Thin.woff2') format('woff2'), url('Gilroy-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-UltraLight.woff2') format('woff2'), url('Gilroy-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-Light.woff2') format('woff2'), url('Gilroy-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-Regular.woff2') format('woff2'), url('Gilroy-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-Medium.woff2') format('woff2'), url('Gilroy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-Semibold.woff2') format('woff2'), url('Gilroy-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-Bold.woff2') format('woff2'), url('Gilroy-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-ExtraboldItalic.woff2') format('woff2'),
    url('Gilroy-ExtraboldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-Heavy.woff2') format('woff2'), url('Gilroy-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-ThinItalic.woff2') format('woff2'), url('Gilroy-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-UltraLightItalic.woff2') format('woff2'),
    url('Gilroy-UltraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-LightItalic.woff2') format('woff2'),
    url('Gilroy-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-RegularItalic.woff2') format('woff2'),
    url('Gilroy-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-MediumItalic.woff2') format('woff2'),
    url('Gilroy-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-SemiboldItalic.woff2') format('woff2'),
    url('Gilroy-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-BoldItalic.woff2') format('woff2'), url('Gilroy-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-Extrabold.woff2') format('woff2'), url('Gilroy-Extrabold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-HeavyItalic.woff2') format('woff2'),
    url('Gilroy-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Century Gothic';
  src: url('CenturyGothic.woff2') format('woff2'),
      url('CenturyGothic.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Century Gothic';
  src: url('CenturyGothic-Bold.woff2') format('woff2'),
      url('CenturyGothic-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
