.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url("../../images/1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Century Gothic';
  font-weight: 400;
  
  .header__overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 750px;
    padding: 10px;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0);
  }

  .header__title {
    display: flex;
    flex-direction: column;

    &-name {
      font-family: 'Century Gothic';
      font-size: 60px;
      line-height: 60px;
      font-family: inherit;
      text-align: center;
      margin: 0 0 44px;
      font-weight: 400;
      color: #fff;
    }
    
    &-span {
      font-family: 'Century Gothic';
      font-weight: 400;
      color: #fff;
      font-size: 30px;
      line-height: 30px;
      margin-bottom: 40px;
      text-align: center;
    }
  }

  .header__text {
    font-size: 14px;
    line-height: 20px;
    font-family: inherit;
    text-align: center;
    color: #fff;
    margin: 0 0 75px;
  }


  @media (min-width: 768px) {
    .header__title {
      &-span {
        margin-bottom: 25px;
      }
      &-name {
        font-size: 97px;
        line-height: 97px;
        margin-bottom: 40px;
      }
    }

    .header__text {
      font-size: 20px;
    }
  }

  @media (min-width: 1360px) {
    .header__title {
      &-name {
        font-size: 140px;
        line-height: 140px;
      }

      &-span {
        font-size: 44px;
        line-height: 30px;
      }
    }
    
    .header__text {
      font-size: 22px;
      line-height: 24px;
    }
  }
}

