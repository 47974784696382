.houses-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  background-color: #ededed;
  padding: 90px 15px 60px !important;

  &__overlay {
    max-width: 600px;
  }

  &__title {
    margin: 8px auto 44px 0;
  }

  &__text {
    line-height: 25px;
    margin: 0 0 30px;
    text-align: justify;
  }

  @media (min-width: 768px) {
    padding-top: 120px !important;

    &__overlay {
      max-width: 960px;
    }

    &__title {
      margin-left: 80px;
      max-width: 450px;
    }

    &__text {
      font-size: 16px;
      line-height: 27px;
      margin-left: 80px;
      max-width: 450px;
    }
  }

  @media (min-width: 1360px) {
    &__overlay {
      max-width: 1200px;
    }

    &__title {
      max-width: 650px;
    }

    &__text {
      font-size: 17px;
      line-height: 30px;
      max-width: 650px;
    }
  }
}

