.cover {
  background: url(../../images/Cover/cover-bg.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &__overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 714px;
    padding: 20px;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.7);
  }

  &__title {
    color: #fff;
    margin: 0 0 25px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    font-family: inherit;
  }

  &__text {
    color: #fff;
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    font-family: inherit;
    font-weight: 300;
    margin: 0 0 25px;
    max-width: 500px;
  }

  &__button {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    font-family: inherit;
    color: #fff;
    padding: 14px 40px;
    border-radius: 5px;
    background-color: #00a806;
    border: 2px solid #00a806;

    &:hover {
      cursor: pointer;
      background-color: transparent;
      transition: all 0.3s ease-out;
    }
  }

  @media (min-width: 600px) {
    &__title {
      font-size: 30px;
    }

    &__text {
      font-size: 15px;
    }
  }

  @media (min-width: 768px) {
    &__overlay {
      min-height: 780px;
    }

    &__title {
      font-size: 40px;
      line-height: 46px;
      margin-bottom: 30px;
    }

    &__text {
      font-size: 17px;
      line-height: 20px;
      margin-bottom: 60px;
    }

    &__button {
      padding: 20px 40px;
    }
  }

  @media (min-width: 1360px) {
    &__title {
      font-size: 44px;
      line-height: 50px;
      margin-bottom: 40px;
    }

    &__text {
      font-size: 18px;
      margin-bottom: 65px;
    }
  }
}
