@import '../../../constants/variables.scss';

.house-detail {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  margin-bottom: 15px;

  &:last-child {
    border-bottom: none;
  }

  &__main-overlay {
    display: flex;
    flex-direction: column;
  }

  &__img-overlay {
    position: relative;
    display: flex;
    margin-bottom: 10px;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
      transition: opacity 0.3s ease-out;
    }
  }

  &__img {
    width: 100%;
    // height: calc(73vw - 30px);
    object-fit: cover;
  }

  &__img-caption {
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: #ededed;
    font-weight: 600;
    font-family: inherit;
    margin: 0;
  }

  &__main-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin-bottom: 7px;
  }

  &__address {
    margin-bottom: 15px;
  }

  &__main-features-list {
    margin: 0 0 10px;
    padding: 0;
    list-style-type: none;
  }

  &__features-list {
    display: flex;
    justify-content: center;
    margin: 0 auto 15px;
  }

  &__feature {
    width: 16px;
    height: 16px;
    margin-right: 3px;
    object-fit: scale-down;

    &:last-child {
      margin-right: 0;
    }
  }

  &__price-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
  }

  &__price {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  &__price-month {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 8px;
  }

  &__drop {
    overflow: hidden;
    transition: max-height 1s ease-out;
  }

  &__features-list-detailed {
    margin: 15px 0;
    padding: 0 15px;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;

    li {
      display: flex;
      align-items: center;
      margin-right: 12px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 3px;
        object-fit: scale-down;
      }

      p {
        margin: 0;
      }
    }
  }

  &__short-description {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    p {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__description-all-houses {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    p {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__buttons-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 0;

    button {
      font-size: 14px;
      line-height: 17px;
      font-family: inherit;
      font-weight: 400;
      padding: 4px 10px;
      border-radius: 4px;
      border: none;
      border: 1px solid $primary-color;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__button-detail {
    color: $primary-color;
    background-color: transparent;

    &:hover {
      color: #fff;
      background-color: $primary-color;
      transition: all 0.3s ease-out;
    }
  }




  @media (min-width: 768px) {
    max-width: 768px;

    &__main-overlay {
      flex-direction: row;
      margin-bottom: 10px;
    }

    &__img-overlay {
      margin-bottom: 0;
      margin-right: 20px;
    }

    &__img {
      width: 250px;
      max-height: 183px;
    }

    &__main-info {
      align-items: flex-start;
    }

    &__price-overlay {
      margin-bottom: 0;
      margin-left: auto;
      margin-top: 10px;
    }

    &__features-list-detailed {
      padding: 0;


      li {
        width: 238px;

        &:nth-child(3n) {
          margin-right: 0;
        }

        img {
          margin-right: 5px;
        }
      }
    }

    &__buttons-overlay {
      margin-left: auto;
    }
  }

  @media (min-width: 1360px) {
    max-width: 900px;
  }
}
