
.contacts {
  display: flex;
  flex-direction: column;

  &__map {
    min-height: 300px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #111;
    padding: 30px 20px;

    h2 {
      color: #fff;
      margin: 0 0 24px;
      font-size: 20px;
      line-height: 24px;
    }

    p, a {
      color: #fff;
    }
  }

  &__tel {
    font-family: inherit;
    font-weight: 300;
    font-size: 19px;
    line-height: 24px;
    text-decoration: none;
  }

  &__email {
    text-decoration: underline;
    text-decoration-color: #ff3838;
    text-decoration-thickness: 2px;
    margin: 0 0 30px;
  }

  @media (min-width: 650px) {
    flex-direction: row;

    &__map {
      flex: 1 1 50%;
      min-height: 300px;
    }

    &__text {
      flex: 1 1 50%;
      box-sizing: border-box;
    }
  }

  @media (min-width: 768px) {
    min-height: 400px;

    &__text {
      padding: 40px;
    }
  }

  @media (min-width: 1360px) {
    min-height: 500px;

    &__text {
      padding: 60px;

      h2 {
        margin-bottom: 30px;
        font-size: 23px;
        line-height: 27px;
      }

      p, a {
        color: #fff;
      }
    }

    &__tel {
      font-size: 19px;
      line-height: 24px;
      text-decoration: none;
    }

    &__email {
      font-size: 17px;
      margin-bottom: 35px;
    }
  }
}
