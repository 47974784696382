.app {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  min-width: 320px;
  padding: 0 auto;
  margin: 0 auto;
}

.section {
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
}
