.info-section {
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  padding: 60px 20px !important;

  &__title {
    margin-bottom: 60px;
  }

  &__description {
    line-height: 25px;
    margin-bottom: 50px;
    text-align: center;
    max-width: 620px;
  }

  &__gallery {
    display: flex;
    flex-direction: column;

  }

  &__image {
    min-width: 280px;
    width: calc(100vw - 40px);
    max-width: 360px;
    height: 360px;
    object-fit: cover;
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    padding: 120px 20px !important;

    &__title {
      line-height: 29px;
    }

    &__description {
      font-size: 16px;
      line-height: 27px;
    }

    &__gallery {
      flex-direction: row;
    }

    &__image {
      margin: 0 10px 0 0;
      min-width: 235px;
      width: 235px;
      height: 250px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media (min-width: 1360px) {
    &__description {
      font-size: 17px;
      line-height: 30px;
    }

    &__image {
      margin-right: 40px;
      width: 360px;
      height: 360px;
    }
  }
}
