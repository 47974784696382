.houses-detail-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;

  @media (min-width: 768px) {
    margin: 50px auto;
  }

  @media (min-width: 1360px) {
    margin: 80px auto;
  }
}
